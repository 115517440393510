/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";


.header-bg-prod-virtwin {
    background-color: #e6008a;
}
.header-bg-prod-xtwin {
    background-color: #5c00e6;
}
.header-bg-prod-exposer {
    background-color: #8020bc;
}
.header-bg-prod-dressmy {
    background-color: #ea731e;
}
.header-bg-prod-lafary {
    background-color: #ea3d1e;
}
.header-bg-test-virtwin {
    background-color: #e089bd;
}
.header-bg-test-xtwin {
    background-color: #bea4e6;
}
.header-bg-test-exposer {
    background-color: #bb8ce4;
}
.header-bg-test-dressmy {
    background-color: #fbc285;
}
.header-bg-test-lafary {
    background-color: #f48181;
}


$female-bg: #ffc0cb;
/* Light pink background for females */
$male-bg: #add8e6;
/* Light pink background for females */

.badge-light-green {
    background-color: rgb(141, 235, 87);
    color: white;
}

.badge-orange {
    background-color: rgb(241, 153, 85);
    color: white;
}

.badge-pink {
    background-color: rgb(238, 75, 132);
    color: white;
}

.badge-purple {
    background-color: rgb(214, 42, 163);
    color: white;
}

.female-row,
.female-row td {
    background-color: #faf0ee !important;
    /* Светлый розовый цвет */
}

.male-row,
.male-row td {
    background-color: #d7e6fc !important;
    /* Светлый голубой цвет */
}

.table th,
.table td {
    min-width: 100px;
    /* Установка минимальной ширины колонок */
}

.badge {
    padding: 0.5em 0.75em;
}

.code-style {
    font-family: 'Courier New', monospace;
    border: 1px solid #ccc;
    padding: 2px 5px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f5f5f5 !important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* stable-diffusion-config.component.scss или другой соответствующий файл стилей */
.green-snackbar {
    background-color: #3acb87;
    color: #fff;
}

/* Ваш файл стилей, например, users.component.scss */

.white-bg {
    background-color: white;
}

.light-yellow-bg {
    background-color: #ffffcc; // Чуть желтоватый
}

.yellow-bg {
    background-color: #ffcc00; // Ярко-желтый
}

.orange-bg {
    background-color: #ff9900; // Оранжевый
}

.red-bg {
    background-color: #ef532c; // Красный
}

.brown-bg {
    background-color: #9d5421; // Коричневый
}

/* Ваш файл стилей, например, styles.scss */

#sidebar {
    a.nav-link {
        color: white; // Цвет текста белый

        &:hover {
            background-color: rgba(255, 255, 255, 0.1); // При наведении добавляем немного белого фона для контраста
        }
    }

    .active {
        // Для активной ссылки
        background-color: rgba(255, 255, 255, 0.2);
        border-left: 3px solid white;
    }
}

.container {
    max-width: 100%;
}


.copyable {
    cursor: copy;
    /* Это изменит курсор на иконку копирования */
}

.avatar-Female {
    background-color: $female-bg;
}

.avatar-Male {
    background-color: $male-bg;
}


.border-male {
    border: 4px solid $male-bg;
}
.border-female {
    border: 4px solid $female-bg;
}

.small-photos-container {
    width: 100%;
    overflow-x: auto;
    img {
        height: 50px;
    }
}